import { Box, Drawer, Typography, Divider, Chip, Link } from "@mui/material";
import { capitalizeFirstLetter, fetchPost, formatStat, getRandomNumber, getRateRange, getStringHash, parseFollowers } from "../util/helpers";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { memo } from "react";
import { Button } from "../components";
const platformToIcon = {
  youtube: (
    <IconBrandYoutube
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  tiktok: (
    <IconBrandTiktok
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  facebook: (
    <IconBrandFacebook
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  instagram: (
    <IconBrandInstagram
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
};
function OutreachDrawer({ open, onClose, creator }) {
  console.log("creator", creator);
    if (!creator) return null;


  return (
    <Drawer open={open} onClose={onClose} anchor="right" style={{zIndex: 100000}}>
      <Box style={{ minWidth: 300, width: "30vw", padding: 10 }}>
        <Box style={{ paddingBottom: 10, paddingTop: 20 }}>
          <Typography variant="h5">Creator Insights</Typography>
        </Box>
        <Divider />
        <Box className="creator-insights-container" display="flex" flexDirection="column" gap={1} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <img
              className="profile-image-large"
              src={
                creator?.profileImage
                  ? creator?.profileImage
                  : `../gradient${getStringHash(creator?.name, 10)}.png`
              }
              alt={creator.name}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h4">{creator?.name}</Typography>
              <Link style={{ textDecoration: 'none' }} href={creator?.tiktok || creator?.youtube || creator?.linkedin || creator?.instagram} target="_blank" rel="noopener noreferrer"><Typography variant="subtitle1">{(creator?.handle !== "-" && creator?.handle) ? `@${creator?.handle} `: creator?.email}</Typography></Link>
              <Box display="flex" flexDirection="row" gap={2}>
                {creator?.industry?.split(",").filter(Boolean).map((industry) => (
                  <Chip key={industry} label={capitalizeFirstLetter(industry.trim())} size="small" sx={{backgroundColor: "var(--neutral-100)", color: "var(--main-text-color)"}}/>
                ))}
              </Box>
            </Box>
          </Box>
          <Box style={{paddingBottom: 10, paddingTop: 10}}>
          <Typography
                            gutterBottom
                            variant="div"
                        >
                            <span variant="h6" style={{ fontWeight: "bold" }}> Summary </span>
                            <br/>
                            This creator has built a great community with over {creator.followers} followers. People follow this creator for {creator.industry} and more topics. They are currently located in {creator.location}, and are relevant for {creator.relevance} campaigns. This creator is within the {creator?.ageBracket} age bracket. Audience Tier is {creator.audience_tier}.  {" "}
                            <br/> <br/> <i>Want to partner with this creator? Launch a campaign and invite them to partner, or send them a custom email below.</i> 
                        </Typography>
        </Box>
        </Box>
       
        <Box style={{ paddingBottom: 10, paddingTop: 10 }}>
          {/* <Typography variant="h5">Overview</Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          sx={{
            border: "1px solid var(--neutral-200)",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Followers</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {creator.followers}
            </Typography>
          </Box>
          <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Relevance</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {creator.relevance}
            </Typography>
          </Box>
          <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Estimated Cost</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
            {getRateRange(parseFollowers(creator.followers))}/post 
            </Typography>
          </Box>
          <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Country</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {creator?.location?.join(", ")}
            </Typography>
          </Box>
          {creator.city && (
                      <Box style={{ width: "30%", textAlign: "center" }}>
                      <Typography variant="subtitle1">City</Typography>
                      <Typography variant="body2" style={{ fontWeight: 600 }}>
                        {creator.city}
                      </Typography>
                    </Box>
          )}

          {formatStat(creator.reach) !== 0 && (
            <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Estimated Views</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {formatStat(creator.reach)}
            </Typography>
          </Box>)}
          {(creator?.linkedin  && formatStat(creator.estimatedEngagement) !== 0) && (
            <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Estimated Engagement</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {formatStat(creator.estimatedEngagement)}
            </Typography>
          </Box>)}

          {/* <Box style={{ width: "30%", textAlign: "center" }}>
            <Typography variant="subtitle1">Audience Tier</Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
            {creator?.audience_tier}
            </Typography>
          </Box> */}

        {/* <Box style={{ paddingBottom: 10, paddingTop: 10 , textAlign: "center", display: "flex", flexDirection: "column"}} alignItems="center" gap={2} >
          <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 4 }}>
            Want to Send a Custom Email?
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: 2, textAlign: "center" }}>
            You can invite this creator to apply for your campaign by sending them a customized email with your campaign link. If you would like to use a Gigl
            templated email, click the "Outreach" button (available in the outreach tab on your campaign page).
          </Typography>
          <Button
            gradient
            href={`mailto:${creator.email}?subject=Gigl: New Campaign Partnership Opportunity`}
          >
            Send a Custom Email
          </Button>
        </Box> */}
        
      </Box>
      <br/>


      <Box className="creator-insights-container" display="flex" flexDirection="column" gap={1} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">

            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h5"> Send a Custom Email</Typography>
            </Box>
          </Box>
          <Box style={{paddingBottom: 10, paddingTop: 1}}>
          <Typography
                            gutterBottom
                            variant="body1" sx={{ marginBottom: 2 }}
                        >
                            <br/>
                            You can invite this creator to apply for your campaign by sending them a customized email with your campaign link. If you would like to use a Gigl
                            templated email, click the Outreach button (available in the outreach tab on your campaign page).
                        </Typography>
                        <br/>

            <Button
            gradient
            style={{borderRadius: 10 }}
            href={`mailto:${creator.email}?subject=Gigl: New Campaign Partnership Opportunity`}
          >
            Send a Custom Email
          </Button>
        </Box>
        </Box>

        </Box>
     
    </Drawer>
  );
}

export default memo(OutreachDrawer);
